import darumaLogo from "./assets/images/daruma-logo.svg";
import "./App.css";

const App = () => {
  const width = window.innerWidth;

  return (
    <main className="app">
      <div>
        <img src={darumaLogo} alt="Daruma" />
        <h1>¡Nos mudamos!</h1>
        <h2>
          Este link ya no está disponible. Te invitamos a guardar en favoritos
          nuestra {width > 768 && <br />} nueva dirección:{" "}
          <a href="https://app.midaruma.com.ar">app.midaruma.com.ar</a>
        </h2>
        <a className="button-link" href="https://app.midaruma.com.ar">
          Ir a Daruma Cuenta
        </a>
        {/* <p>
          ¿Querés iniciar sesión en Daruma Pago?{" "}
          <span>
            <a href="https://pago.midaruma.com.ar">Hacé click acá</a>
          </span>
        </p> */}
      </div>
    </main>
  );
};

export default App;
